(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name services.factory:HttpInterceptorNoServerFound
   *
   * @description
   *
   */
  angular
    .module('neo.services')
    .factory('HttpInterceptorNoServerFound', HttpInterceptorNoServerFound);

  function HttpInterceptorNoServerFound($q, $injector) {
    return {
     /* // optional method
      'request': function (config) {
        // do something on success
        return config;
      },

      // optional method
      'requestError': function (rejection) {
        // do something on error
        if (canRecover(rejection)) {
          return responseOrNewPromise
        }
        return $q.reject(rejection);
      },
*/

     /* // optional method
      'response': function (response) {
        // do something on success
        return response;
      },
*/
      // optional method
      'responseError': function (rejection) {
        if(rejection.status === -1){
          var stateService = $injector.get('$state');
          stateService.go('serverNotFound');
        }
        return $q.reject(rejection);
      }
    }
  }}());
